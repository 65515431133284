import React, { useEffect, useState } from 'react'
import dic from '../../../../dictionary'
import { Grid, Box, IconButton, Tabs, Tab } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { getAxiosInstance } from '../../../../axiosInstance'
import { AWS_TECHNOLOGIES, ROUTES } from '../../../../consts'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../loader'
import { TechnologyIcon } from '../default/defaultTechnologyCatalog.style'
import RepositoriesTable from './components/repositories'

const EcrTechnologyCatalog = () => {
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(0)
  const [ecrComponentsData, setEcrComponentsData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ecrComponents = await getAxiosInstance().get('/component', { params: { technology: AWS_TECHNOLOGIES.ECR } })
        setEcrComponentsData(ecrComponents.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return <Loader />
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const onBackIconClick = () => {
    navigate(ROUTES.CATALOG.ALL)
  }

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
        <IconButton onClick={onBackIconClick}>
          <ArrowBack />
        </IconButton>
        <TechnologyIcon src={`https://draftt-public.s3.amazonaws.com/icons/${AWS_TECHNOLOGIES.ECR}.svg`} alt={AWS_TECHNOLOGIES.ECR} />
        <h1>
          {dic.catalog.specific_technology.title} / {dic.catalog.specific_technology.ecr.title}
        </h1>
      </Box>

      {/*<Tabs value={tabValue} onChange={handleChange} aria-label="Tabs" sx={{ paddingBottom: '20px', paddingTop: '20px' }}>*/}
      {/*  <Tab label={`${dic.catalog.specific_technology.ecr.tabs.overview}`} />*/}
      {/*  <Tab label={`${dic.catalog.specific_technology.ecr.tabs.repositories.title}`} />*/}
      {/*</Tabs>*/}

      {/*{tabValue === 0 && (*/}
      {/*  <Grid container flexDirection="row">*/}
      {/*<Grid item xs={6}>*/}
      {/*  <VersionBreakdownChart />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/*  <VersionBreakdownChart />*/}
      {/*</Grid>*/}
      {/*</Grid>*/}
      {/*)}*/}
      {/*{tabValue === 1 &&*/}
      <RepositoriesTable ecrComponents={ecrComponentsData} />
    </Grid>
  )
}

export default EcrTechnologyCatalog
