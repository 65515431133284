import { styled as muiStyled } from '@mui/material/styles'
import { StepLabel, Typography } from '@mui/material'

export const CustomStepLabel = muiStyled(StepLabel)`
    align-items: 'center',
    display: 'flex',
    flex-direction: 'column'
`
export const StepTitle = muiStyled(Typography)` 
    cursor: pointer;
    font-weight: bold;
    color: black; 
    font-size: 12.5px;

    &:hover {
        text-decoration: underline;
    }
`

export const StepSubtitle = muiStyled(Typography)`
    font-size: 11px;
`
