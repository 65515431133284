import { Route, Routes, Navigate } from 'react-router-dom'
import { ROUTES } from './consts'
import Policies from './components/policies'
import Policy from './components/policy'
import Grid from '@mui/material/Grid'
import Sidebar from './components/sidebar'
import { AppGrid, MainContentGrid } from './common.css'
import PageLoader from './components/loader'
import { useInitializeAxiosWithAuth } from './axiosInstance'
import Catalog from './components/catalog'
import TechnologyCatalog from './components/catalog/technologies/'
import { useSession, useUser } from '@descope/react-sdk'
import { Descope } from '@descope/react-sdk'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import AccessKeys from './components/settings/accessKeys'
import ActiveDraftts from './components/draftts'
import Draftt from './components/draftts/drafttDetails'
import Integrations from './components/integrations'
import IntegrationsByType from './components/integrations/integrationsByType'
import IntegrationsInstructions from './components/integrations/integrationsInstructions'
import AccountSettings from './components/settings/account'
import Dashboard from './components/dashboard'
import UserManagement from './components/settings/userManagement'
import EcrRepoView from './components/catalog/technologies/ecr/repoView'
import Certificates from './components/certificate'
dayjs.extend(relativeTime)

const App = () => {
  const { isAuthenticated, isSessionLoading } = useSession()
  const { user, isUserLoading } = useUser()

  useInitializeAxiosWithAuth()

  if (!isAuthenticated) {
    return (
      <Grid container justifyContent="center">
        <Descope flowId="sign-in" onError={(e) => console.log('Could not log in!')} />
      </Grid>
    )
  }

  if (isSessionLoading || isUserLoading) {
    return <PageLoader />
  }

  if (!isUserLoading && isAuthenticated) {
    return (
      <AppGrid>
        <Sidebar />
        <MainContentGrid>
          <Routes>
            <Route path={ROUTES.POLICIES.ALL} element={<Policies />} />
            <Route path={ROUTES.POLICIES.SINGLE} element={<Policy />} />
            <Route path={ROUTES.CATALOG.ALL} element={<Catalog />} />
            <Route path={ROUTES.CATALOG.SPECIFIC_TECHNOLOGY} element={<TechnologyCatalog />} />
            <Route path={ROUTES.CATALOG.ECR_REPO} element={<EcrRepoView />} />
            <Route path={ROUTES.SETTINGS.ACCESS_KEYS} element={<AccessKeys />} />
            <Route path={ROUTES.SETTINGS.USER_MANAGEMENT} element={<UserManagement />} />
            <Route path={ROUTES.DRAFTTS.ALL} element={<ActiveDraftts />} />
            <Route path={ROUTES.DRAFTTS.DETAILS} element={<Draftt />} />
            <Route path={ROUTES.INTEGRATIONS.ALL} element={<Integrations />} />
            <Route path={ROUTES.INTEGRATIONS.BY_TYPE} element={<IntegrationsByType />} />
            <Route path={ROUTES.INTEGRATIONS.CREATE} element={<IntegrationsInstructions />} />
            <Route path={ROUTES.SETTINGS.ACCOUNT} element={<AccountSettings />} />
            <Route path={ROUTES.CERTIFICATES.ALL} element={<Certificates />} />
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path="*" element={<Navigate to={ROUTES.DASHBOARD} replace />} />
          </Routes>
        </MainContentGrid>
      </AppGrid>
    )
  }

  return <PageLoader />
}

export default App
