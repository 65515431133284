import { useLocation, useNavigate } from 'react-router-dom'
import dic from '../../dictionary'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { ROUTES } from '../../consts'
import { Box, Drawer, Grid, IconButton, Menu, MenuItem } from '@mui/material'
import { Logout, Dashboard, Policy, Storage, Timeline, IntegrationInstructions, Settings, WorkspacePremium } from '@mui/icons-material'
import { Footer, DrafttLogo, SidebarContainer, SidebarItem, SidebarIcon } from './sidebar.style'
import { useDescope } from '@descope/react-sdk'
import _ from 'lodash'
import { useState } from 'react'

const Sidebar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)

  const { logout } = useDescope()

  const sidebarItems = [
    {
      title: dic.sidebar.dashboard,
      icon: Dashboard,
      paths: [ROUTES.DASHBOARD],
      onClick: () => navigate(ROUTES.DASHBOARD),
    },
    {
      title: dic.sidebar.catalog,
      icon: Storage,
      paths: [ROUTES.CATALOG.ALL, ROUTES.CATALOG.SPECIFIC_TECHNOLOGY, ROUTES.CATALOG.ECR_REPO],
      onClick: () => navigate(ROUTES.CATALOG.ALL),
    },
    {
      title: dic.sidebar.certificates,
      icon: WorkspacePremium,
      paths: [ROUTES.CERTIFICATES.ALL],
      onClick: () => navigate(ROUTES.CERTIFICATES.ALL),
    },
    {
      title: dic.sidebar.policies,
      icon: Policy,
      paths: [ROUTES.POLICIES.ALL, ROUTES.POLICIES.SINGLE],
      onClick: () => navigate(ROUTES.POLICIES.ALL),
    },
    {
      title: dic.sidebar.draftts,
      icon: Timeline,
      paths: [ROUTES.DRAFTTS.ALL, ROUTES.DRAFTTS.DETAILS],
      onClick: () => navigate(ROUTES.DRAFTTS.ALL),
    },
    {
      title: dic.sidebar.integrations,
      icon: IntegrationInstructions,
      paths: [ROUTES.INTEGRATIONS.ALL, ROUTES.INTEGRATIONS.BY_TYPE, ROUTES.INTEGRATIONS.CREATE],
      onClick: () => navigate(ROUTES.INTEGRATIONS.ALL),
    },
  ]

  const isCurrentPage = (itemPaths: string[]) => {
    return _.some(itemPaths, (pathPattern) => {
      const pathSegments = _.filter(_.split(pathPattern, '/'), (p) => !_.isEmpty(p))
      const currentPathSegments = _.filter(_.split(location.pathname, '/'), (p) => !_.isEmpty(p))

      if (pathSegments.length !== currentPathSegments.length) {
        return false // Different lengths, can't be a match
      }

      return _.every(pathSegments, (segment, i) => {
        return _.startsWith(segment, ':') || segment === currentPathSegments[i]
      })
    })
  }

  const handleSettingsMenuClose = () => {
    setAnchorEl(null)
  }

  const handleSettingsMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSettingMenuClick = (route: any) => {
    navigate(route)
    handleSettingsMenuClose()
  }

  return (
    <Drawer variant="permanent" hideBackdrop={true}>
      <SidebarContainer container direction="column" justifyContent="space-between">
        <Grid container direction="column" justifyContent="center">
          <Grid container direction="row" justifyContent="center">
            <DrafttLogo />
          </Grid>
          <List>
            {sidebarItems.map((item, index) => (
              <SidebarItem key={item.title} className={isCurrentPage(item.paths) ? 'active' : ''} disablePadding>
                <ListItemButton divider={true} onClick={item.onClick}>
                  <SidebarIcon>
                    <item.icon />
                  </SidebarIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </SidebarItem>
            ))}
          </List>
        </Grid>
        <Footer container direction="row" justifyContent="center" alignItems="center">
          <Box>
            <IconButton aria-label="account" onClick={handleSettingsMenuOpen}>
              <Settings />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleSettingsMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={() => handleSettingMenuClick(ROUTES.SETTINGS.ACCOUNT)}>{dic.sidebar.settings.account}</MenuItem>
              <MenuItem onClick={() => handleSettingMenuClick(ROUTES.SETTINGS.ACCESS_KEYS)}>{dic.sidebar.settings.access_keys}</MenuItem>
              <MenuItem onClick={() => handleSettingMenuClick(ROUTES.SETTINGS.USER_MANAGEMENT)}>{dic.sidebar.settings.user_management}</MenuItem>
            </Menu>
          </Box>
          <IconButton aria-label="logout" onClick={() => logout()}>
            <Logout />
          </IconButton>
        </Footer>
      </SidebarContainer>
    </Drawer>
  )
}

export default Sidebar
