import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Loader from '../../loader'
import { Grid, IconButton, InputAdornment, Paper, TextField, Tooltip } from '@mui/material'
import dic from '../../../dictionary'
import { getAxiosInstance } from '../../../axiosInstance'
import { Info } from '@mui/icons-material'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useNotification } from '../../commons/notification/notificationProvider'
import { NOTIFICATION_TYPES } from '../../../consts'

const FETCH_INTERVAL = {
  MIN: 1,
  MAX: 24,
  DEFAULT: 8,
}

const AccountSettings = () => {
  const { showNotification } = useNotification()
  const [organization, setOrganization] = useState([])
  const [loading, setLoading] = useState(true)
  const [fetchInterval, setFetchInterval] = useState(FETCH_INTERVAL.DEFAULT)
  const [fetchIntervalError, setFetchIntervalError] = useState('')
  const [updateLoading, setUpdateLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationProps = await getAxiosInstance().get(`/organization`)
        setOrganization(_.get(organizationProps, 'data[0]'))
        setFetchInterval(_.get(organizationProps, 'data[0].fetchInterval', FETCH_INTERVAL.DEFAULT))
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return <Loader />
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setUpdateLoading(true)
    setFetchIntervalError('')
    try {
      await getAxiosInstance().put('/organization', {
        fetchInterval,
      })
      showNotification(dic.settings.account.update.success, NOTIFICATION_TYPES.SUCCESS)
    } catch (error: any) {
      setUpdateLoading(false)
      showNotification(dic.settings.account.update.failure, NOTIFICATION_TYPES.ERROR)
    } finally {
      setUpdateLoading(false)
    }
  }

  const handleFetchIntervalChange = (event: any) => {
    const newValue = event.target.value
    setFetchInterval(newValue)

    if (newValue < FETCH_INTERVAL.MIN || newValue > FETCH_INTERVAL.MAX) {
      setFetchIntervalError(
        `${dic.settings.account.fetchInterval.error.between} ${FETCH_INTERVAL.MIN} ${dic.settings.account.fetchInterval.error.and} ${FETCH_INTERVAL.MAX}.`,
      )
    } else {
      setFetchIntervalError('')
    }
  }

  return (
    <Grid container direction="column">
      <h1>{dic.settings.account.title}</h1>
      <form onSubmit={handleSubmit}>
        <Paper variant="outlined" sx={{ p: '16px', mr: '8px' }}>
          <Grid container flexDirection="row" alignItems="start">
            <TextField
              label={dic.settings.account.name}
              defaultValue={_.get(organization, 'organizationName')}
              disabled
              variant="standard"
              sx={{ minWidth: '300px', mr: '30px' }}
            />
            <TextField
              type="number"
              label={dic.settings.account.fetchInterval.title}
              value={fetchInterval}
              variant="standard"
              onChange={handleFetchIntervalChange}
              error={!!fetchIntervalError}
              helperText={fetchIntervalError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={dic.settings.account.fetchInterval.tooltip}>
                      <IconButton size="small">
                        <Info fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
                inputProps: {
                  min: FETCH_INTERVAL.MIN,
                  max: FETCH_INTERVAL.MAX,
                },
              }}
              sx={{ minWidth: '300px', minHeight: '72px' }}
            />
          </Grid>
          <Grid container flexDirection="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: '20px' }}>
            {!updateLoading && (
              <Button type="submit" variant="contained" color="primary" disabled={loading || !fetchInterval || !!fetchIntervalError}>
                {dic.settings.account.update.title}
              </Button>
            )}
            {updateLoading && <CircularProgress color="primary" />}
          </Grid>
        </Paper>
      </form>
    </Grid>
  )
}

export default AccountSettings
