import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dic from '../../dictionary'
import { ROUTES, TAGS_DELIMITER } from '../../consts'
import { getAxiosInstance } from '../../axiosInstance'
import Loader from '../loader'
import _ from 'lodash'
import { Box, Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import CertificateTable from './components/certificateTable'
import CertificatesFutureTimeline from './components/certificatesFutureTimeline'
import { useGridApiRef } from '@mui/x-data-grid-pro'

const Certificates = () => {
  const navigate = useNavigate()
  const apiRef = useGridApiRef()
  const [certificatesData, setCertificatesData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const filterByNotAfterDate = (date: any) => {
    apiRef.current.setFilterModel({
      items: [{ field: 'notAfter', operator: 'contains', value: date }],
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const certificates = await getAxiosInstance().get(`/certificate`)
        const integrations = await getAxiosInstance().get(`/integration`)

        const parsedCertificates = _.map(certificates.data, (certificate) => {
          let integration = _.find(integrations.data, (integration) => certificate.integrationId === integration.id)
          return {
            ...certificate,
            integrationName: _.get(integration, 'name'),
            awsAccountId: _.chain(_.get(certificate, 'details.awsArn')).split(':').nth(4).value() || undefined,
            region: _.chain(_.get(certificate, 'details.awsArn')).split(':').nth(3).value() || undefined,
          }
        })

        setCertificatesData(parsedCertificates)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        navigate(ROUTES.DASHBOARD)
      }
    }
    fetchData()
  }, [])

  const tagsFilters: GridColDef[] = useMemo(() => {
    const allTags = _.sortBy(_.uniq(_.flatMap(certificatesData, (certificate: any) => _.map(certificate.details?.tags, (tag: any) => tag.key))), [
      (item) => item.toLowerCase(),
    ])

    return _.map(allTags, (tag) => ({
      field: `${TAGS_DELIMITER}${tag}`,
      headerName: tag,
      flex: 2,
      hide: true,
      renderCell: (params: any) => {
        return _.get(
          _.find(params.row.tags, (t: any) => t.key === tag),
          'value',
          '',
        )
      },
    }))
  }, [certificatesData])

  if (loading) {
    return <Loader />
  }

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
        <h1>{dic.certificates.title}</h1>
      </Box>

      <CertificatesFutureTimeline certificates={certificatesData} onFilterByDate={filterByNotAfterDate} />

      <Grid container>
        <CertificateTable certificates={certificatesData} tagsFilters={tagsFilters} apiRef={apiRef} />
      </Grid>
    </Grid>
  )
}

export default Certificates
