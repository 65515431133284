import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dic from '../../dictionary'
import { getAxiosInstance } from '../../axiosInstance'
import Loader from '../loader'
import { Grid, Paper } from '@mui/material'
import { WidgetTitle } from '../../common.css'
import IntegrationsSummary from './components/integrationsSummary'
import MetricsSummary from './components/metricsSummary'
import EolPieChart from './components/eolPieChart'
import LatestAndGreatestPieChart from './components/latestAndGreatestPieChart'
import Insights from './components/insights'

const Dashboard = () => {
  const navigate = useNavigate()

  const [integrationsData, setIntegrationsData] = useState([])
  const [componentsData, setComponentsData] = useState([])
  const [policyComponentsData, setPolicyComponentsData] = useState([])
  const [drafttsData, setDrafttsData] = useState([])
  const [policiesData, setPoliciesData] = useState([])
  const [certificatesData, setCertificatesData] = useState([])

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        let dataPromises = [
          getAxiosInstance().get(`/policy`),
          getAxiosInstance().get(`/policyComponent`),
          getAxiosInstance().get(`/component`),
          getAxiosInstance().get(`/draftt`),
          getAxiosInstance().get(`/integration`),
          getAxiosInstance().get(`/certificate`),
        ]

        const [policiesData, policyComponentsData, componentsData, drafttsData, integrationsData, certificatesData] = await Promise.all(dataPromises)
        setPoliciesData(policiesData.data)
        setPolicyComponentsData(policyComponentsData.data)
        setComponentsData(componentsData.data)
        setDrafttsData(drafttsData.data)
        setIntegrationsData(integrationsData.data)
        setCertificatesData(certificatesData.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        // TODO: handle error
        // navigate(ROUTES.POLICIES.ALL)
        // setError('Error fetching data');
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={12} sx={{ mb: '16px' }}>
        <Insights integrations={integrationsData} policyComponents={policyComponentsData} />
      </Grid>
      <Grid item flexDirection="column" xs={6}>
        <Paper variant="outlined" sx={{ p: '16px', mb: '16px' }}>
          <WidgetTitle>{dic.dashboard.pieCharts.title}</WidgetTitle>
          <Grid container flexDirection="row" sx={{ mt: '16px' }}>
            <Grid item xs={6}>
              <EolPieChart policyComponents={policyComponentsData} />
            </Grid>
            <Grid item xs={6}>
              <LatestAndGreatestPieChart policyComponents={policyComponentsData} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={6} sx={{ mb: '16px' }}>
        <MetricsSummary
          integrations={integrationsData}
          components={componentsData}
          draftts={drafttsData}
          policies={policiesData}
          certificates={certificatesData}
        />
      </Grid>
      <Grid item xs={12}>
        <IntegrationsSummary integrations={integrationsData} components={componentsData} certificates={certificatesData} />
      </Grid>
    </Grid>
  )
}

export default Dashboard
