import React, { useMemo, useState } from 'react'
import dic from '../../../../dictionary'
import _ from 'lodash'
import { Grid, IconButton, Link } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from '@mui/icons-material'
import { GridColDef, GridColumnVisibilityModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { DataGridPro, GridCallbackDetails, GridFilterItem, GridFilterModel } from '@mui/x-data-grid-pro'
import ComponentTags from '../../../commons/componentTags'
import { TAGS_DELIMITER } from '../../../../consts'

const DATE_FORMAT = 'MMM D, YYYY h:mm A'
const EXPIRED_STATUS = 'EXPIRED'

const CertificateTable = (props: any) => {
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 })

  let columns: GridColDef[] = [
    {
      field: 'id',
      headerName: dic.certificates.table.id,
      flex: 3,
      renderCell: (params) => {
        const awsCertificateUrl = `https://${params.row.region}.console.aws.amazon.com/acm/home?region=${params.row.region}#/certificates/${params.row.id}`

        const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.stopPropagation()
        }

        return (
          <Grid container alignItems="center">
            <Link
              href={awsCertificateUrl}
              target="_blank"
              onClick={handleLinkClick}
              sx={{
                '&:hover .icon-button': {
                  opacity: 1,
                },
              }}
            >
              {params.row.id}
              <IconButton
                className="icon-button"
                sx={{
                  opacity: 0,
                  transition: 'opacity 0.2s',
                }}
              >
                <OpenInNew fontSize="small" />
              </IconButton>
            </Link>
          </Grid>
        )
      },
    },
    {
      field: 'type',
      flex: 2,
      headerName: dic.certificates.table.type,
    },
    {
      field: 'status',
      flex: 2,
      headerName: dic.certificates.table.status,
    },
    {
      field: 'issuer',
      flex: 2,
      headerName: dic.certificates.table.issuer,
    },
    {
      field: 'keyAlgorithm',
      flex: 2,
      headerName: dic.certificates.table.key_algorithm,
    },
    {
      field: 'renewalEligibility',
      flex: 2,
      headerName: dic.certificates.table.renewal_eligibility,
    },
    {
      field: 'domain',
      headerName: dic.certificates.table.domain,
      flex: 2,
    },
    {
      field: 'inUseBy',
      headerName: dic.certificates.table.in_use.title,
      flex: 1,
      renderCell: (params: any) => {
        return params.value?.length > 0 ? dic.certificates.table.in_use.yes : dic.certificates.table.in_use.no
      },
    },
    {
      field: 'createdAt',
      headerName: dic.certificates.table.created_at,
      flex: 2,
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
    },
    {
      field: 'issuedAt',
      headerName: dic.certificates.table.issued_at,
      flex: 2,
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
    },
    {
      field: 'notAfter',
      headerName: dic.certificates.table.not_after,
      flex: 2,
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
    },
    {
      field: 'notBefore',
      headerName: dic.certificates.table.not_before,
      flex: 2,
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
    },
    {
      field: 'integrationName',
      headerName: dic.certificates.table.integration_name,
    },
    {
      field: 'awsAccountId',
      headerName: dic.certificates.table.awsAccountId,
    },
    {
      field: 'region',
      headerName: dic.certificates.table.region,
    },
    ...props.tagsFilters,
  ]

  const rows = _.map(props.certificates, (certificate) => {
    return {
      id: _.get(certificate, 'uniqueIdentifier'),
      type: _.startCase(_.lowerCase(_.get(certificate, 'type'))),
      status: _.capitalize(_.lowerCase(_.get(certificate, 'status'))),
      issuer: _.get(certificate, 'issuer'),
      keyAlgorithm: _.get(certificate, 'keyAlgorithm'),
      renewalEligibility: _.capitalize(_.lowerCase(_.get(certificate, 'renewalEligibility'))),
      domain: _.get(certificate, 'domainName'),
      domains: _.get(certificate, 'domains'),
      inUseBy: _.get(certificate, 'inUseBy'),
      createdAt: certificate.created ? dayjs(certificate.created).format(DATE_FORMAT) : undefined,
      issuedAt: certificate.issuedAt ? dayjs(certificate.issuedAt).format(DATE_FORMAT) : undefined,
      notAfter: certificate.notAfter ? dayjs(certificate.notAfter).format(DATE_FORMAT) : undefined,
      notBefore: certificate.notBefore ? dayjs(certificate.notBefore).format(DATE_FORMAT) : undefined,
      tags: _.get(certificate, 'details.tags'),
      integrationName: _.get(certificate, 'integrationName'),
      awsAccountId: _.get(certificate, 'awsAccountId'),
      region: _.get(certificate, 'region'),
      ..._.reduce(
        _.get(certificate, 'details.tags', []),
        (acc: any, tag) => {
          acc[`${TAGS_DELIMITER}${_.get(tag, 'key')}`] = _.get(tag, 'value')
          return acc
        },
        {},
      ),
    }
  })

  const initialVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    const visibilityModel = _.reduce(
      props.tagsFilters,
      (model: GridColumnVisibilityModel, col) => {
        model[col.field] = false
        return model
      },
      {} as GridColumnVisibilityModel,
    )

    visibilityModel['integrationName'] = false
    visibilityModel['awsAccountId'] = false
    visibilityModel['region'] = false
    visibilityModel['keyAlgorithm'] = false
    visibilityModel['renewalEligibility'] = false
    visibilityModel['createdAt'] = false

    return visibilityModel
  }, [props.tagsFilters])

  const getDetailPanelContent = (params: any) => {
    return (
      <Grid container margin={2} spacing={1} flexDirection="column" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <strong>{dic.certificates.table.expandable.renewal_eligibility}: </strong>
          <span>{_.capitalize(_.lowerCase(_.get(params, 'row.renewalEligibility')))}</span>
        </Grid>

        <Grid item>
          <strong>{dic.certificates.table.expandable.key_algorithm}: </strong>
          <span>{_.get(params, 'row.keyAlgorithm')}</span>
        </Grid>

        {_.get(params, 'row.inUseBy', []).length > 0 && (
          <Grid item>
            <strong>{dic.certificates.table.expandable.in_use_by}:</strong>
            <ul>
              {_.map(_.get(params, 'row.inUseBy'), (useBy: any) => (
                <li key={useBy}>{useBy}</li>
              ))}
            </ul>
          </Grid>
        )}

        {_.get(params, 'row.domains', []).length > 1 && (
          <Grid item>
            <strong>{dic.certificates.table.expandable.domains}:</strong>
            <ul>
              {_.map(_.get(params, 'row.domains'), (domain: any) => (
                <li key={domain}>{domain}</li>
              ))}
            </ul>
          </Grid>
        )}

        <Grid item>
          <strong>{dic.certificates.table.expandable.integration_name}: </strong>
          <span>{_.get(params, 'row.integrationName')}</span>
        </Grid>

        <Grid item>
          <strong>{dic.certificates.table.expandable.created_at}:</strong>
          <span> {_.get(params, 'row.createdAt')}</span>
        </Grid>

        <Grid item>
          <strong>{dic.certificates.table.expandable.updated_at}:</strong>
          <span> {dayjs(_.get(params, 'row.updated_at')).format('MMM D, YYYY')}</span>
        </Grid>

        {_.get(params, 'row.tags', []).length > 0 && <ComponentTags tags={_.get(params, 'row.tags')} />}
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <DataGridPro
        rows={rows}
        columns={columns}
        apiRef={props.apiRef}
        disableRowSelectionOnClick={true}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        slots={{
          detailPanelExpandIcon: KeyboardArrowDown,
          detailPanelCollapseIcon: KeyboardArrowUp,
          toolbar: () => {
            return (
              <GridToolbarContainer sx={{ pt: '8px' }}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
              </GridToolbarContainer>
            )
          },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: initialVisibilityModel,
          },
        }}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'white',
          },
        }}
      />
    </Grid>
  )
}

export default CertificateTable
