import React, { useState } from 'react'
import _ from 'lodash'
import dic from '../../../../dictionary'
import { Box, Step, Stepper, Typography, IconButton, Grid, Paper } from '@mui/material'
import { CustomStepLabel, StepSubtitle, StepTitle } from './certificatesFutureTimeline.style'
import { ArrowBackIos, ArrowForwardIos, Circle } from '@mui/icons-material'
import dayjs from 'dayjs'

const NUM_OF_EVENTS = 5
const DOMAINS_LIMIT = 30

const CertificatesFutureTimeline = (props: any) => {
  const [currentStep, setCurrentStep] = useState(0)

  const sortedCertificates = _.sortBy(props.certificates, (certificate) => certificate.notAfter)

  const filteredFutureCertificates = _.filter(sortedCertificates, (certificate) => {
    return dayjs(certificate.notAfter).isAfter(dayjs())
  })

  const groupedCertificates = _.groupBy(filteredFutureCertificates, (certificate) => `${dayjs(certificate.notAfter).format('YYYY-MM-DD')}`)

  const displayGroups = _.entries(groupedCertificates)

  const visibleGroups = displayGroups.slice(currentStep, currentStep + NUM_OF_EVENTS)

  const handleNext = () => {
    if (currentStep + NUM_OF_EVENTS < displayGroups.length) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const handleCertificateClick = (date: any) => {
    props.onFilterByDate(dayjs(date).format('MMM D, YYYY'))
  }

  return (
    <Paper variant="outlined" sx={{ pb: '20px', mb: '20px' }}>
      <Typography variant="subtitle2" sx={{ m: '10px 10px 20px 10px', fontSize: '16px' }}>
        {dic.certificates.future_timeline.title}
      </Typography>

      <Grid container flexDirection="row" alignItems="center" justifyContent="center">
        {displayGroups.length > NUM_OF_EVENTS && (
          <Grid container item xs={1} alignItems="center" justifyContent="center">
            <IconButton onClick={handleBack} disabled={currentStep === 0}>
              <ArrowBackIos />
            </IconButton>
          </Grid>
        )}

        <Grid container item xs={displayGroups.length > NUM_OF_EVENTS ? 10 : 12} alignItems="center" justifyContent="center">
          <Stepper activeStep={-1} alternativeLabel sx={{ width: '100%' }}>
            {_.map(visibleGroups, ([groupKey, certificates]: [string, any[]]) => {
              const firstCertificate = _.first(certificates)
              const notAfter = firstCertificate?.notAfter

              const domains = _.uniq(_.map(certificates, (certificate) => `${certificate.domainName}`))
              const domainsString = domains.join(', ')

              const displayText = domainsString.length > DOMAINS_LIMIT ? `${domainsString.slice(0, DOMAINS_LIMIT)}...` : domainsString

              return (
                <Step key={groupKey}>
                  <CustomStepLabel
                    icon={
                      <Box display="flex" justifyContent="center">
                        <Circle color="primary" fontSize="small" />
                      </Box>
                    }
                  >
                    <Box textAlign="center">
                      <StepTitle variant="body2" onClick={() => handleCertificateClick(groupKey)}>
                        {_.size(certificates) === 1
                          ? `${_.size(certificates)} ${dic.certificates.future_timeline.certificate_step.singular}`
                          : `${_.size(certificates)} ${dic.certificates.future_timeline.certificate_step.plural}`}
                      </StepTitle>
                      <StepSubtitle variant="subtitle1"> {displayText} </StepSubtitle>
                      <StepSubtitle variant="subtitle1"> {dayjs(notAfter).format('MMM D, YYYY')} </StepSubtitle>
                    </Box>
                  </CustomStepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Grid>

        {displayGroups.length > NUM_OF_EVENTS && (
          <Grid container item xs={1} alignItems="center" justifyContent="center">
            <IconButton onClick={handleNext} disabled={currentStep + NUM_OF_EVENTS >= displayGroups.length}>
              <ArrowForwardIos />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default CertificatesFutureTimeline
