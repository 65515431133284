import { useParams } from 'react-router-dom'
import EcrTechnologyCatalog from './ecr'
import DefaultTechnologyCatalog from './default'
import { AWS_TECHNOLOGIES } from '../../../consts'

const componentMap: Record<any, any> = {
  [AWS_TECHNOLOGIES.ECR]: EcrTechnologyCatalog,
}

const TechnologyCatalog = () => {
  const { technologyName } = useParams()
  let ComponentToRender

  if (technologyName) {
    ComponentToRender = componentMap[technologyName]
  }

  if (!ComponentToRender) {
    ComponentToRender = DefaultTechnologyCatalog
  }

  return <ComponentToRender />
}

export default TechnologyCatalog
